<template>
  <b-form @submit.prevent="onSubmit">
    <b-container class="full-w">
      <b-row>
        <b-col cols="12" lg="8">
          <b-card>
            <b-form-group
              :label="$t('category.name')"
              label-for="category-name"
              :class="{ error: v$.category.name.$errors.length }"
            >
              <b-form-input
                v-model="category.name"
                name="category-name"
                @blur="presetSlug"
              />
              <div
                class="input-errors"
                v-for="error of v$.category.name.$errors"
                :key="error.$uid"
              >
                <small class="text-danger">{{ error.$message }}</small>
              </div>
            </b-form-group>
          </b-card>

          <b-card>
            <b-form-group class="mb-0">
              <tinymce
                :content="category.description"
                @editorContentChanged="updateContent"
              />
            </b-form-group>
          </b-card>
        </b-col>

        <b-col cols="12" lg="4">
          <b-card>
            <b-form-group :label="$t('forms.publish')" label-for="published">
              <b-form-checkbox
                v-model="category.onOff"
                name="published"
                switch
              />
            </b-form-group>

            <b-form-group :label="$t('category.visible')" label-for="visible">
              <b-form-checkbox
                v-model="category.visible"
                name="visible"
                switch
              />
            </b-form-group>

            <b-form-group :label="$t('category.publishDate')">
              <datepicker v-model="category.date" format="dd. MM. yyyy | HH:mm"
                          previewFormat="dd. MM. yyyy | HH:mm"></datepicker>
            </b-form-group>

            <b-form-group
              :label="$t('category.parentCategory')"
              label-for="parentCategory"
              v-if="repository == 'categories'"
            >
              <v-select
                v-model="category.parent"
                :options="availableCategories"
              />
            </b-form-group>

            <b-form-group
              :label="$t('forms.excerpt')"
              label-for="category-excerpt"
            >
              <b-form-textarea
                v-model="category.excerpt"
                name="category-excerpt"
              />
            </b-form-group>

            <b-form-group
              label="URL"
              label-for="category-slug"
              :class="{ error: v$.category.url.$errors.length }"
            >
              <b-form-input
                v-model="category.url"
                name="category-slug"
                @blur="slugifyURL"
              />
              <div
                class="input-errors"
                v-for="error of v$.category.url.$errors"
                :key="error.$uid"
              >
                <small class="text-danger">{{ error.$message }}</small>
              </div>
            </b-form-group>

            <b-form-group
              :label="$t('forms.keywords')"
              label-for="category-keywords"
            >
              <b-form-input
                v-model="category.keywords"
                name="category-keywords"
              />
            </b-form-group>

            <b-form-group
              :label="$t('forms.metaDescription')"
              label-for="category-slug"
            >
              <b-form-textarea
                v-model="category.metaDescription"
                name="category-desc"
              />
            </b-form-group>

            <b-form-group :label="$t('forms.tags')">
              <v-select
                taggable
                multiple
                push-tags
                v-model="category.tags"
                :options="category.tags"
              />
            </b-form-group>

            <image-upload
              :preview-image="category.fileUrl"
              :label="$t('forms.featuredImage')"
              @fileUploaded="ilustrationImageChange"
              v-if="category['@id']"
              :resource="category['@id']"
              file-attribute="FILE"
            />
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </b-form>
</template>

<script>
import moment from "moment";
import vSelect from "vue-select";
import tinymce from "@/components/tinymce.vue";
import ImageUpload from "@/components/ImageUpload.vue";
import useVuelidate from "@vuelidate/core";
import ListUtils from "@/mixins/ListUtils";
import ResourceUtils from "@/mixins/ResourceUtils";
import Datepicker from '@vuepic/vue-datepicker';

export default {
  components: {
    vSelect,
    tinymce,
    ImageUpload,
    Datepicker
  },
  props: {
    categoryObj: {
      type: Object, default() {
      }
    },
    action: {type: String, default: null},
    repository: {type: String, default: "categories"},
  },
  data() {
    return {
      category: this.categoryObj,
      availableCategories: [],
    };
  },
  mixins: [ListUtils, ResourceUtils],
  async created() {
    let currentId = null;
    if ("id" in this.category) {
      currentId = this.category.id;
    }
    await this.getCategoriesList(this.availableCategories, currentId);
    if (this.category.parent) {
      this.category.parent = this.availableCategories.find(
        (c) => c.id === this.category.parent
      );
    }
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      category: {
        name: {required: this.$translateError("required")},
        url: {required: this.$translateError("required")},
      },
    };
  },
  watch: {
    action() {
      this.onSubmit();
    },
  },
  methods: {
    ilustrationImageChange(data) {
      this.category.file = data["@id"];
      this.category.fileURL = data.url;
    },
    presetSlug() {
      if (this.category.url.length === 0) {
        this.category.url = this.$helper.slugifyURL(this.category.name);
      }
    },
    slugifyURL() {
      if (this.category.url.length !== 0) {
        this.category.url = this.$helper.slugifyURL(this.category.url);
      }
    },
    updateContent(content) {
      this.category.description = content;
    },
    async onSubmit() {
      const isValid = await this.v$.$validate();

      if (isValid) {
        const body = {
          name: this.category.name,
          description: this.category.description,
          date: moment(this.category.date).format(),
          onOff: this.category.onOff,
          visible: this.category.visible,
          parent: this.category.parent ? this.category.parent.id : null,
          url: this.category.url,
          file: this.category.file,
          excerpt: this.category.excerpt,
          keywords: this.category.keywords,
          metaDescription: this.category.metaDescription,
          tags: this.category.tags,
        };

        if (this.action) {
          this.action === "add"
            ? this.create(
              this.$Categories,
              body,
              this.$t("category.created"),
              null,
              this.success,
              this.error
            )
            : this.update(
              this.$Categories,
              this.$route.params.id,
              body,
              this.$t("category.updated"),
              null,
              this.successUpdate,
              this.error
            );
        }
      } else {
        this.$emit("clearAction");
      }
    },
    success() {
      this.$router.push(`/categories`);
    },
    successUpdate() {
      this.$emit("clearAction");
    },
    error() {
      this.$emit("clearAction");
    },
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>
