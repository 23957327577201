<template>
  <div v-if="category">
    <title-bar
      submitBtn
      :submitBtnTitle="$t('category.update')"
      :title="$t('category.update')"
      @submitPressed="submitPressed"
    />
    <category-form :category-obj="category" :action="action" @clearAction="action = null" />

    <b-card>
      <custom-fields ownerResource="CATEGORY" :ownerId="category.id" :existingCustomFields="category.customFields" />
    </b-card>
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import CategoryForm from './components/CategoryForm.vue';
import CustomFields from "@/components/CustomFields/customFields.vue";

export default {
  components: {
    TitleBar,
    CategoryForm,
    CustomFields
  },
  data() {
    return {
      action: null,
      category: null
    }
  },
  created() {
    this.loadCategory()
  },
  methods: {
    loadCategory() {
      const id = this.$route.params.id;
      this.$Categories.getResource({ id }).then((response) => {
        this.category = response.data
      });
    },
    submitPressed() {
      this.action = 'update'
    }
  }
};
</script>
